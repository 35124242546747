import Vue from "vue"
import Router from "vue-router"
 
Vue.use(Router)
export default new Router({
    mode:"history",
    base:process.env.BASE_URL,
    routes:[{
        path:'/',
        name:'Login',
        component :()=> import('@/pages/loginPage.vue'),
        meta:{
            title:'登录'
        }
    },{
        path:'/main',
        name:'Index',
        component :()=> import('@/pages/indexPage.vue'),
        meta:{
            title:'主页'
        }
    },{
        path:'/mhIndex',
        name:'MhIndex',
        component :()=> import('@/pages/mhindexPage.vue'),
        meta:{
            title:'主页'
        }
    },{
        path:'/lyIndex',
        name:'LyIndex',
        component :()=> import('@/pages/lyindexPage.vue'),
        meta:{
            title:'主页'
        }
    },{
        path:'/llIndex',
        name:'LlIndex',
        component :()=> import('@/pages/llindexPage.vue'),
        meta:{
            title:'主页'
        }
    },{
        path:'/ldxxPage',
        name:'LdxxPage',
        component :()=> import('@/pages/ldxxPage.vue'),
        meta:{
            title:'主页'
        }
    }]
})